import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyArH6kGLZG3Lu9Ki_GPIVoCoyDMtWE_uGg",
  authDomain: "website-mockup-generator-1ea7d.firebaseapp.com",
  projectId: "website-mockup-generator-1ea7d",
  storageBucket: "website-mockup-generator-1ea7d.appspot.com",
  messagingSenderId: "13311913449",
  appId: "1:13311913449:web:4d2d78b256794c8905af78",
  measurementId: "G-T13QW2R2TK"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
